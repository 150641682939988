import {useState, useEffect} from 'react';
import {FaceLivenessDetector} from '@aws-amplify/ui-react-liveness';
import {ThemeProvider, Alert, View, Heading} from '@aws-amplify/ui-react';
import axios from "axios";
import getAccessToken from "../../utils/getAccessToken";
import '@aws-amplify/ui-react/styles.css';
import Loading from "../loading";
import dictionary from "./dictionary";
import outputs from '../../amplify_outputs.json';
import {Amplify} from "aws-amplify";

Amplify.configure(outputs);


export function AwsLiveness(props) {
  const [loading, setLoading] = useState(true);
  const [createLivenessApiData, setCreateLivenessApiData] = useState(null);
  const [tokenQuery] = useState(props.data.token ? props.data.token : "");
  const [token, setToken] = useState()

  useEffect(() => {

    async function getToken() {
      setToken(await getAccessToken)
    }

    getToken()
  }, []);


  useEffect(() => {
    // TODO todo este useEffect no debiera usarse, hay que buscar la solución adecuada
    let widthDetectedTime = null;

    const unhiddeInterval = setInterval(() => {
      // por algún motivo el ocmponente no muestra los colores, este canvas no se está mostrando cuando corresponde
      const canvas = document.querySelector('.amplify-liveness-freshness-canvas');

      if (canvas) {
        // con esto forzamos su visibilidad
        canvas.removeAttribute('hidden');
        canvas.style.visibility = 'visible';

        // cuando el js de aws le agrega width, empieza la animación de los colores
        if (canvas.hasAttribute('width') && !widthDetectedTime) {
          widthDetectedTime = Date.now();
        }

        if (widthDetectedTime && Date.now() - widthDetectedTime >= 5000) {
          clearInterval(unhiddeInterval);
        }
      }
    }, 100);

    return () => clearInterval(unhiddeInterval);
  }, []);

  const createLiveness = async () => {
    await axios({
      url: process.env.REACT_APP_BACKEND_URL + `/validation/liveness_session/?token=${tokenQuery}&reception=${props.data.reception}`,
      method: 'GET',
      mode: "no-cors",
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + token
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('No se pudo crear la session')
        }
        setCreateLivenessApiData(res.data);
        setLoading(false);
      })
      .catch(() => {
        console.log("error conexión liveness")
      })
  };


  useEffect(() => {
    if (!token)
      return

    createLiveness();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const handleAnalysisComplete = async () => {
    setLoading(true);
    props.data.liveness_id = createLivenessApiData.sessionId;
    props.next(props.data, 2)
  };
  return (
    <ThemeProvider>
      {loading ? (
        <Loading/>
      ) : (
        <FaceLivenessDetector
          sessionId={createLivenessApiData.sessionId}
          region="us-east-1"
          onAnalysisComplete={handleAnalysisComplete}
          onError={(error) => {
            console.error(error);
          }}
          onUserCancel={() => {
            setLoading(true)
            createLiveness()
          }}
          disableStartScreen={true}
          displayText={dictionary.es}
        />
      )}
    </ThemeProvider>
  );
}
