"use client";

import React, {useEffect, useState} from "react";
import '../../styles/capture.css';
import '../../styles/slider.css';
// import VideoStream from "./VideoStream";
// import VideoStream2 from "./VideoStream2";
import {AwsLiveness} from "./AwsLiveness";
import VerificationState from "../VerificationState";

function TestLiveness() {
  const [data, setData] = useState({
    false_negatives: [],
    carnet: "",
    frontal_image: "",
    reception: true,
  })
  const [showFirstDiv, setShowFirstDiv] = useState(true);

  const toggleDiv = () => {
    console.log("toggle!")
    setShowFirstDiv(!showFirstDiv);
  };

  useEffect(() => {
    // console.log("data")
    // console.log(data)
  }, [data])

  function setingdata(newData) {
    setData(prev => ({...prev, ...newData}))
  }

  return (
    <div className="container-validation">
      <VerificationState page={1} rut={"-"} dni={data.dni} dni_type={"rut"}/>
      <div className="container">
        <button onClick={toggleDiv}>Cambiar Stream</button>
        <div>showFirstDiv: {showFirstDiv}</div>
        {
          showFirstDiv ? (
            <>
              <div>stream aws</div>
              {/*<VideoStream data={data} next={setingdata}/>*/}
              <AwsLiveness next={setingdata} data={data}/>
            </>
          ) : (
            <>
              <div>streaming</div>
              {/*<VideoStream2/>*/}
            </>
          )
        }
      </div>
    </div>
  )

}

export default TestLiveness;