import React from 'react';
import {
  Route, Switch,
} from 'react-router-dom';
import Layout from './components/layout';
// import LoginRut from './views/LoginRut'
import NotFound from './views/NotFound';
import AuthContextProvider from './contexts/AuthContext';
import LoginUrl from './views/LoginUrl';
import Home from "./views/Home";
import Login from './views/Login';
import TableVerifications from './views/TableVerifications';
import NewVerification from './views/NewVerification';
import ViewVerification from './views/ViewVerification';
import Locations from "./views/Locations";
import CheckBackendStatus from "./views/CheckBackendStatus";
import TestAutoCamera from "./components/autoPhoto/testcv";
import TestFlujoNuevo from "./components/autoPhoto/testFlujoNuevo";
import LoginMultiID from "./views/LoginMultiID";
import AutoFlujo from "./views/AutoFlujo";
import TestLiveness from "./components/liveness/testliveness";
// import RevisarCorreo from './views/CodigoEmail';
// import Welcome from './views/Welcome';
// import UserProfile from './views/Profile'
// import Validar from './views/Validar'
// import Historial from './views/Historial'
// import EditPersonalData from './views/ChangePersonalData';
// import EditPassword from './views/ChangePassword';
// import EditEmail from './views/ChangeEmail';
// import ResumeSend from './views/ResumeSend';
// import Registro from './views/Registro';
// import CodigoEmail from './views/EmailSignUp';
// import CodigoListo from './views/CodigoListo';
// import TomarFotos from './views/TomarFotos';
// import FotoEmocion from './views/FotoEmocion';
// import CuentaLista from './views/CuentaLista';
// import ValidarEmocion from './views/ValidarEmocion';
// import GoogleSignUp from './views/GoogleSignUp';
// import GoogleDataReady from './views/GoogleDataReady';

function Routes() {
  return (
    <AuthContextProvider>
      <Layout>
        <Switch>
          {/*<Route exact path="/" component={Login} />*/}
          <Route exact path="/login" component={Login} />
          {/*<Route exact path="/autofoto" component={TestAutoCamera} />+/ /*urls de debug*/ }
          {/*<Route exact path="/testflujo" component={TestFlujoNuevo} />  /!*urls de debug*!/ */}
          <Route exact path="/testliveness" component={TestLiveness} />  {/*urls de debug*/}
          <Route exact path="/auto_generacion" component={AutoFlujo} />
          {/*<Route exact path="/register" component={Registro} />*/}
          {/*<Route exact path="/email-code" component={CodigoEmail} />*/}
          {/*<Route exact path="/email-code-success" component={CodigoListo} />*/}
          {/*<Route exact path="/capture" component={TomarFotos} />*/}
          {/*<Route exact path="/emotion-capture" component={FotoEmocion} />*/}
          {/*<Route exact path="/account-success" component={CuentaLista} />*/}
          {/*<Route exact path="/home" component={Welcome} />*/}
          {/*<Route exact path="/check-email" component={RevisarCorreo} />*/}
          {/*<Route exact path="/profile" component={UserProfile} />*/}
          {/*<Route exact path="/validate" component={Validar} />*/}
          {/*<Route exact path="/history" component={Historial} />*/}
          {/*<Route exact path="/profile/edit" component={EditPersonalData} />*/}
          {/*<Route exact path="/profile/change-password" component={EditPassword} />*/}
          {/*<Route exact path="/profile/change-email" component={EditEmail} />*/}
          {/*<Route exact path="/overview/send-verification/:rut/:mail" component={ResumeSend} />*/}
          {/*<Route exact path="/validate-emotion" component={ValidarEmocion}/>*/}
          {/*<Route exact path="/google-sign-up" component={GoogleSignUp}/>*/}
          {/*<Route exact path="/not-found" component={NotFound} />*/}
          {/*<Route exact path="/google-data-ready" component={GoogleDataReady} />*/}

          {/* Flujo de verificaciones */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/v/:id?" component={LoginMultiID} />
          <Route exact path="/validate/:details" component={LoginUrl} />
          <Route exact path="/need-info" component={Home} />

          {/* Dashboard admin */}
          <Route exact path="/verifications" component={TableVerifications} />
          <Route exact path="/verifications/:id" component={ViewVerification}/>
          <Route exact path="/overview" component={NewVerification} />
          <Route exact path="/locations" component={Locations} />

          {/* Checkear estado de conexión con backend */}
          <Route exact path="/status" component={CheckBackendStatus} />

          {/* Restantes */}
          <Route exact path="/*" component={Home} />
          <Route component={NotFound} />

        </Switch>
      </Layout>
    </AuthContextProvider>
  );
}

export default function RoutesWrapper() {
  return (
    <Routes />
  );
}
