const dictionary = {
    // use default strings for english
    es: {
        "hintMoveFaceFrontOfCameraText": "Mueve tu cara al frente de la cámara",
        "hintTooManyFacesText": "Demasiadas caras detectadas",
        "hintFaceDetectedText": "Cara detectada",
        "hintCanNotIdentifyText": "No se puede identificar",
        "hintTooCloseText": "Demasiado cerca",
        "hintTooFarText": "Demasiado lejos",
        "hintConnectingText": "Conectando...",
        "hintVerifyingText": "Verificando...",
        "hintCheckCompleteText": "Verificación completa",
        "hintIlluminationTooBrightText": "Iluminación demasyarn iado brillante",
        "hintIlluminationTooDarkText": "Iluminación demasiado oscura",
        "hintIlluminationNormalText": "Iluminación normal",
        "hintHoldFaceForFreshnessText": "Mantén la cara en la posición correcta",
        "hintCenterFaceText": "Centra tu cara",
        "hintCenterFaceInstructionText": "Por favor, centra tu cara en la cámara",
        "hintFaceOffCenterText": "Cara fuera de centro",
        "hintMatchIndicatorText": "Indicador de coincidencia",
        "cameraMinSpecificationsHeadingText": "Especificaciones mínimas de la cámara",
        "cameraMinSpecificationsMessageText": "Tu cámara no cumple con las especificaciones mínimas",
        "cameraNotFoundHeadingText": "Cámara no encontrada",
        "cameraNotFoundMessageText": "No se ha detectado ninguna cámara disponible",
        "retryCameraPermissionsText": "Reintentar permisos de cámara",
        "waitingCameraPermissionText": "Esperando permiso para usar la cámara",
        "a11yVideoLabelText": "Vista previa de video",
        "goodFitCaptionText": "Buena posición",
        "goodFitAltText": "Tu cara está bien posicionada",
        "photosensitivityWarningBodyText": "Este proceso puede contener luces intermitentes",
        "photosensitivityWarningHeadingText": "Advertencia de fotosensibilidad",
        "photosensitivityWarningInfoText": "Si eres sensible a la luz, ten cuidado",
        "photosensitivityWarningLabelText": "Advertencia de fotosensibilidad",
        "startScreenBeginCheckText": "Comenzar verificación",
        "tooFarCaptionText": "Demasiado lejos",
        "tooFarAltText": "Acércate un poco más a la cámara",
        "recordingIndicatorText": "Grabando...",
        "cancelLivenessCheckText": "Cancelar verificación de liveness",
        "errorLabelText": "Error",
        "connectionTimeoutHeaderText": "Tiempo de conexión agotado",
        "connectionTimeoutMessageText": "No se pudo conectar a tiempo",
        "timeoutHeaderText": "Tiempo agotado",
        "timeoutMessageText": "El proceso tomó demasiado tiempo",
        "faceDistanceHeaderText": "Distancia de la cara incorrecta",
        "faceDistanceMessageText": "Por favor ajusta la distancia de tu cara a la cámara",
        "multipleFacesHeaderText": "Demasiadas caras detectadas",
        "multipleFacesMessageText": "Asegúrate de que solo una persona esté frente a la cámara",
        "clientHeaderText": "Error del cliente",
        "clientMessageText": "Hubo un error en tu dispositivo",
        "serverHeaderText": "Error del servidor",
        "serverMessageText": "Hubo un error en el servidor",
        "landscapeHeaderText": "Modo paisaje detectado",
        "landscapeMessageText": "Por favor, usa el dispositivo en modo retrato",
        "portraitMessageText": "Modo retrato detectado",
        "tryAgainText": "Inténtalo de nuevo"

    },
};

export default dictionary;