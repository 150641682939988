import React, { useState } from "react";
import '../../styles/validation.css'
import IconSuccess from '../../images/icon-success.svg';
import Loading from "../loading";
import {IconWarning} from "@aws-amplify/ui-react/internal";

export default function AlmostReady (props) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    setLoading(true);
    props.next(props.data, 1)
  }

  const renderMessage = () => {
    return <h3 className="verification-complete-paragraph">Ahora una prueba de rostro.</h3>
  }

  const flashWarning = () => {
    return (
      <div>
        <div className="alert-warning align-items-center rounded-3 mb-4 p-2 text-justify">
          <span className="d-inline-block align-text-top me-2" >
            <IconWarning className="d-flex" style={{"padding-top": "0.125rem"}}/>
          </span>
          <span>
            Esta validación contiene secuencias de luces itermitentes que pueden afectar a personas sensibles a la luz.
          </span>
        </div>
      </div>
    )
  }

  if(loading){
    return <Loading />
  }
  return (
    <div className="almost-ready-container">
      <div className='figure' id="circle">
        <img className='icon-success'
          src={IconSuccess}
          alt="success icon" />
      </div>
      <h1 className="text-complete-verification">Casi listos</h1>
      {renderMessage()}
      {flashWarning()}
      <button className="button is-link" id="almost-button" onClick={handleSubmit}>Continuar</button>
    </div>
  );
}